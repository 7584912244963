<template>
    <div class="main inform-page">
        <section class="main-width">
            <div class="row no-gutters res-sides">
                <div class="col-12 col-lg-2">
                    <div class="left-side">
                        <ul>
                            <li><router-link  exact-active-class="active"  :to="{ name: 'video-general'}"> {{ $t('VideoTutorials.general') }} </router-link></li>
                            <!-- <li><router-link  :to="{ name: 'video-supplier' }"> {{ $t('VideoTutorials.for-suppliers') }} </router-link></li> -->
                        </ul>
                    </div>
                </div>
                <div class="col-12 col-lg-10">
                    <router-view></router-view>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>
.inform-page {
    // padding-top: 70px;
}
</style>